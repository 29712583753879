import logo from "./walking-duck.webp";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>DatenEnte.DE</h1>
        <p>Die Seite, die es nicht gibt.</p>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
